<template>
    <div class="page talron main-page scan-page">

<layout-solid-blob title="talron.accessibilityStatement.viewTitle" >
  <div class="ac-doc">
    <p>
    טלרון מצברים בע"מ, אחראית על הקמת והפעלת אתר : <a href="https://eservice.talronbatt.co.il">https://eservice.talronbatt.co.il</a>. אנו רואים חשיבות רבה במתן שירות שוויוני לכלל האזרחים ובשיפור השירות הניתן לאזרחים עם מוגבלות.
<br>אנו משקיעים משאבים רבים בהנגשת האתר והנכסים הדיגיטליים שלנו על מנת להפוך את שירותי החברה לזמינים יותר עבור אנשים עם מוגבלות.
</p>
    <p>
    במדינת ישראל כ-20 אחוזים מקרב האוכלוסייה הינם אנשים עם מוגבלות הזקוקים לנגישות דיגיטלית, על מנת לצרוך מידע ושירותים כללים.
<br>הנגשת האתר של טלרון, נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אוכלוסיות עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות,
<br>לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית על בני הגיל השלישי.
</p>
    <p>
    הנגשת אתר זה בוצעה על ידי חברת הנגשת האתרים "Vee הנגשת אתרים".
    </p>

    <h2>
      רמת הנגישות באתר - AA
    </h2>
    <p>
    חברת "Vee", התאימה את נגישות האתר לדפדפנים הנפוצים ולשימוש בטלפון הסלולרי ככל הניתן, והשתמשה בבדיקותיה בקוראי מסך מסוג Jaws ו- NVDA.
<br>מקפידה על עמידה בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות 5568 התשע"ג 2013 ברמת AA. וכן, מיישמת את המלצות מסמך WCAG2.2 מאת ארגון W3C.</p>
<p>בעברית: <a href="https://www.isoc.org.il/files/w3c-wai/guidelines.html">הנחיות לנגישות תכנים באינטרנט</a>
<br>באנגלית:  <a href="https://www.w3.org/TR/WCAG20/">Web Content Accessibility Guidelines (WCAG) 2.0</a>
   </p>
<p>
הנגשת האתר בוצעה בהתאם <a href="https://www.gov.il/he/departments/policies/accessibility_of_applications_in_web_browsers">להנחיות רשות התקשוב להנגשת יישומים בדפדפני אינטרנט</a>.
</p>

    <h2>
      כיצד עוברים למצב נגיש?
    </h2>

    <p>
באתר מוצב אייקון נגישות (בד"כ בדפנות האתר). לחיצה על האייקון מאפשרת פתיחת של תפריט הנגישות. לאחר בחירת הפונקציה המתאימה בתפריט יש להמתין לטעינת הדף ולשינוי הרצוי בתצוגה (במידת הצורך).
    </p>
    <p>
במידה ומעוניינים לבטל את הפעולה, יש ללחוץ על הפונקציה בתפריט פעם שניה. בכל מצב, ניתן לאפס הגדרות נגישות
    </p>
    <p>
    התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera בכפוף (תנאי יצרן) הגלישה במצב נגישות מומלצת בדפדפן כרום.
    </p>
    <p>
    האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.
    </p>
    <p>
    לצורך קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת  NVDA העדכנית ביותר.
    </p>
    <h2>
     תיקונים והתאמות שבוצעו באתר:
    </h2>

    <p>
    התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
<br>אמצעי הניווט באתר פשוטים וברורים.
<br>תכני האתר כתובים באופן ברור, מסודר והיררכי. 
<br>האתר מותאם לצפייה בדפדפנים מודרניים.
<br>התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.
<br>כל הדפים באתר בעלי מבנה קבוע (1H/2H/3H וכו').
<br>לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt).
    </p>

    <h2>
      פונקציונליות תוכנת נגישות:
    </h2>

    <p>
    התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
<br>עצירת הבהובים - עצירת אלמנטים נעים וחסימת אנימציות
<br>דילוג ישיר לתוכן - דילוג על התפריט הראשי ישירות אל התוכן.
<br>התאמה לניווט מקלדת.
<br>הגדלה / הקטנה של טקסט.
<br>ריווח בין אותיות / מילים / שורות.
<br>ניגודיות וצבע - גבוהה, הפוכה, שחור לבן.
<br>גופן קריא.
<br>הדגשת קישורים.
<br>מדריך קריאה.
<br>שינוי אייקון סמן עכבר.
<br>תיאור לתמונות
    </p>
    
    <h2>
    החרגות
    </h2>
    
    <p>
    חשוב לציין, כי למרות מאמצינו להנגיש את כלל הדפים והאלמנטים באתר, ייתכן שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו
    </p>
    
    <p>
    אנו פועלים לשפר את נגישות האתר שלנו כל העת, כחלק ממחויבותנו לאפשר לכלל האוכלוסייה להשתמש בו, כולל אנשים עם מוגבלות.
    </p>
<p>הסדרי נגישות בסניפי החברה:</p>
            <div class="uk-overflow-auto">
              <table uk-table class="uk-table uk-table-divider uk-table-striped">
                <thead>
                <tr>
                  <th class="uk-text-right">שם הסניף</th>
                  <th class="uk-text-right">כתובת</th>
                  <th class="uk-text-right">עיר</th>
                  <th class="uk-text-right">מצב נגישות</th>
                  <th class="uk-text-right">חניות נכים</th>
                  <th class="uk-text-right">שירותים נגישים</th>
                  <th class="uk-text-right">שילוט הכוונה</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>טלרון מודיעין</td>
                  <td>המעיין 7</td>
                  <td>מודיעין</td>
                  <td>נגיש</td>
                  <td>קיים</td>
                  <td>קיים</td>
                  <td>קיים</td>
                </tr>
                <tr>
                  <td>טלרון סניף דרום</td>
                  <td>האגס 2</td>
                  <td>באר שבע</td>
                  <td>קיים</td>
                  <td>קיים</td>
                  <td>קיים</td>
                  <td>קיים</td>
                </tr>
                <tr>
                  <td>טלרון סניף צפון</td>
                  <td>יוסף בצרי 16</td>
                  <td>קריית אתא</td>
                  <td>קיים</td>
                  <td>קיים</td>
                  <td>קיים</td>
                  <td>קיים</td>
                </tr>
                </tbody>
              </table>
            </div>

    <h2>
      יצירת קשר בנושא נגישות
    </h2>
    
    <p>
    במידה ונתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות באמצעות פנייה לרכז הנגישות שלנו:
<br>על מנת שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים ככל שניתן:
    </p>
    
    <ul>
      <li>תיאור הבעיה</li>
      <li>מהי הפעולה שניסיתם לבצע.</li>
      <li>קישור לדף שבו גלשתם.</li>
      <li>סוג הדפדפן וגרסתו.</li>
      <li>מערכת הפעלה.</li>
      <li>סוג הטכנולוגיה המסייעת (במידה והשתמשתם).</li>
    </ul>
    
    <p>
      טלרון מצברים בע"מ תעשה ככל יכולה על מנת להנגיש את האתר בצורה המיטבית ולענות לפניות בצורה המקצועית והמהירה ביותר.
    </p>

    <p>
      פרטי רכז הנגישות:
    </p>
    
    <p>
    <b style="color:#4472c4">שם: הגברת נסיה יפרח</b>
    </p>
    <p>
      <b style="color:#4472c4">טלפון:</b><a href="tel:03-6710341">03-6710341</a>
    </p>
    <p>
      <b style="color:#4472c4">דוא"ל:</b><a href="mailto:nesyaif@lubinski.co.il">nesyaif@lubinski.co.il</a>
    </p>
    <p>
    תאריך עדכון הצהרת נגישות <span style="color:#4472c4">09-07-2024</span>
    </p>
    
  </div>
</layout-solid-blob>

</div>
</template>



<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
export default {
    layout    : 'default',
    middleware: {

    },
    setup (props, context) {
        let {meta}                                 = useMeta({title: useI18n().t('talron.accessibilityStatement.pageTitle')}).meta

        return {
          meta
        }
    },
    data() {
        return {
            temp : [],
            contactMethods: [

            ],
        }
    },
    components: {

    },
    computed  : {


    },
    methods   : {

    },
    mounted() {

    },

    watch: {},

}
</script>

<style lang="scss" scoped>
h2{
  margin-top:0;
}
.uk-overflow-auto {
    margin-bottom: 20px;
}
</style>




